import { Component, OnDestroy, } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule, Event } from '@angular/router';
import { distinctUntilChanged, filter, map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ContentService } from './shared/services/content/content.service';
import { AuthService } from './modules/auth/services/auth.service';
import { SessionExpiringDialogComponent } from './shared/components/dialogs/session-expiring-dialog/session-expiring-dialog.component';
import { UserTypes } from './shared/models/global';
import { NgIf } from '@angular/common';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { IdleService } from './shared/services/timeout/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterModule, SessionExpiringDialogComponent, NgIf, LoaderComponent]
})
export class AppComponent implements OnDestroy {
  onDestroy = new Subject();
  loading = true;

  constructor(
    private authService: AuthService,
    private idleService: IdleService,
    private router: Router,
    private contentService: ContentService,
  ) {
    this.stopAppLoader();

    // this.startSessionTimer();
    // registerSvgIcons(this.matIconRegistry, this.domSanitizer);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  /**
   * Stop the HTML Loader on navigationEnd
   */
  private stopAppLoader() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy),
        // we only want one NavigationEnd  event to filter through
        filter((event: Event) => event instanceof NavigationEnd),
        tap(data => {
          this.loading = false;
        }),
      ).subscribe();
  }

  /**
   * Start the Session Timout timer for logged-in users only
   */
  private startSessionTimer() {
    this.authService.authState$
      .pipe(
        takeUntil(this.onDestroy),
        // only allow observable value through when id and login status have changed
        distinctUntilChanged((prev, current) => (
          prev.id === current.id ||
          prev.isLoggedIn === current.isLoggedIn
        )),
        filter(val => val.isLoggedIn),
        switchMap(val => {
          return this.contentService.getContent(['expiry-dialog:heading', 'expiry-dialog:body'])
            .pipe(
              map(() => val)
            )
        })
      )
      .subscribe(val => {
        const role = val.groups.includes(UserTypes.SUPER_ADMIN) ?
          UserTypes.SUPER_ADMIN : val.groups.includes(UserTypes.FACILITY_ADMIN) ?
            UserTypes.FACILITY_ADMIN : UserTypes.FACILITY_USER;
        this.idleService.startTimer(role);
      });
  }
}